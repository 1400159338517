<template>

    <span class="loader"></span>
   
</template>

<script>
export default {
    name: 'MainLoading'
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container .loader {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    display: block;
    border: 12px solid #0a67a8;
    border-top: 12px solid transparent;
    border-radius: 50%;
    
    animation: animationLoading 1s linear infinite;
}

@keyframes animationLoading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>