<template>
  <div class="">
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
    />
    <!-- Footer -->
    <footer class="text-center text-lg-start text-muted w-150">
      <!-- Section: Social media -->
      <section
        class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
      >
        <!-- Left -->
        <div class="me-5 d-none d-lg-block">
          <span id="follow-me">Siga a gente!</span>
        </div>
        <!-- Left -->

        <!-- Right -->
        <div class="social-media">
          <a
            href="https://www.facebook.com/cdconectec/"
            target="_blank"
            class="me-4 text-reset"
          >
            <img src="/img/facebook_logo_icon.svg" alt="" />
          </a>
          <!--<a href="" class="me-4 text-reset">
            <img src="/img/twitter.svg" alt="" />
          </a>-->
          <a
            href="https://www.instagram.com/conecteccertificadodigital/"
            target="_blank"
            class="me-4 text-reset"
          >
            <img src="/img/instagram_logo_icon.svg" alt="" />
          </a>
          <!--<a href="" class="me-4 text-reset">
            <img src="/img/tiktok.svg" alt="" />
          </a>-->
          <a
            href="https://api.whatsapp.com/send?l=pt&phone=5512996470999&text=Novo%20Contato%20via%20Site"
            target="_blank"
            class="me-4 text-reset"
          >
            <img src="/img/whatsapp_logo_icon.svg" alt="" />
          </a>
          <a
            href="https://www.google.com/search?q=conectec+certifica%C3%A7%C3%A3o"
            target="_blank"
            class="me-4 text-reset"
          >
            <img src="/img/google_logo_icon.svg" alt="" />
          </a>
        </div>
        <!-- Right -->
      </section>
      <!-- Section: Social media -->

      <!-- Section: Links  -->
      <section class="text-center ms-5">
        <div class="container text-center text-md-start mt-5">
          <!-- Grid row -->
          <div class="row mt-3">
            <!-- Grid column -->
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-1">
              <h6 class="text-uppercase fw-bold mb-4">Home</h6>
              <router-link to="/produtos">
                <p class="footer-adress">Produtos</p>
              </router-link>
              <router-link to="/sobrenos">
                <p class="footer-adress">Quem Somos</p>
              </router-link>
              <router-link to="/duvidas">
                <p class="footer-adress">Dúvidas? Nós te ajudamos!</p>
              </router-link>
              <router-link to="/requisitos">
                <p class="footer-adress">Requisitos para videochamada</p>
              </router-link>
              <router-link to="/dpn">
                <p class="footer-adress">Declaração de práticas de negócio</p>
              </router-link>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-1">
              <!-- Links -->
              <h6 class="text-uppercase fw-bold mb-4">Matriz</h6>
              <p class="footer-adress">
                <i class="bi bi-house-fill me-2"></i>
                Rua Marabá, 110 - Parque Industrial
              </p>
              <p class="footer-adress">
                <i class="bi bi-geo-alt-fill me-2"></i>
                São José dos Campos - SP, 12235-830
              </p>
              <p class="footer-adress">
                <a
                  href="https://api.whatsapp.com/send?l=pt&phone=551239383633&text=Novo%20Contato%20via%20Site"
                  target="_blank"
                  class="text-reset"
                  ><i class="bi bi-telephone-fill me-2"></i> (12) 3938-3633</a
                >, opção 2
              </p>
              <p class="footer-adress">
                <i class="bi bi-envelope-fill me-2"></i>
                <a href="mailto:contato@cdconectec.com.br"
                  >contato@cdconectec.com.br</a
                >
              </p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-1">
              <!-- Links -->
              <h6 class="text-uppercase fw-bold mb-4">Filial</h6>
              <p class="footer-adress">
                <i class="bi bi-house-fill me-1"></i>
                Rua São Bento do Sapucaí, 38 - Alto do Cardoso
              </p>
              <p class="footer-adress">
                <i class="bi bi-geo-alt-fill me-2"></i>
                Pindamonhangaba - SP, 12420-190
              </p>
              <p class="footer-adress">
                <a
                  href="https://api.whatsapp.com/send?l=pt&phone=5512996267699&text=Novo%20Contato%20via%20Site"
                  target="_blank"
                  class="text-reset"
                  ><i class="bi bi-telephone-fill me-2"></i> (12) 99626-7699</a
                >
              </p>
              <p class="footer-adress">
                <i class="bi bi-envelope-fill me-2"></i>
                <a href="mailto:anacarolina@cdconectec.com.br"
                  >anacarolina@cdconectec.com.br</a
                >
              </p>
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->
        </div>
      </section>
      <!-- Section: Links  -->

      <section
        class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom border-top"
        id="payment-options"
      >
        <!-- Left -->
        <h6 class="text-uppercase fw-bold mt-3">Meios de Pagamento</h6>
        <!-- Left -->

        <!-- Right -->
        <div class="meios-pagamento">
          <span href="" class="me-4 text-reset">
            <img src="/img/visa.svg" alt="" id="visa" />
          </span>
          <span href="" class="me-4 text-reset">
            <img src="/img/mastercard.svg" alt="" />
          </span>
          <span href="" class="me-4 text-reset">
            <img src="/img/american-express.svg" alt="" />
          </span>
          <span href="" class="me-4 text-reset">
            <img src="/img/dinersclub.svg" alt="" id="dinersclub" />
          </span>
          <span href="" class="me-4 text-reset">
            <img src="/img/elo.svg" alt="" />
          </span>
          <span href="" class="me-4 text-reset">
            <img src="/img/hipercard.svg" alt="" />
          </span>
          <span href="" class="me-4 text-reset">
            <img src="/img/boleto.svg" alt="" id="boleto" />
          </span>
          <span href="" class="me-4 text-reset">
            <img src="/img/pix.svg" alt="" style="width: 60px" id="pix" />
          </span>
        </div>
        <!-- Right -->
      </section>

      <!-- Copyright -->
      <div
        class="text-center p-4 descriptition"
        style="background-color: #0a67a8"
      >
        <img src="/img/logo_bco.svg" class="mb-3 me-4" style="width: 5%" />
        © {{ currentYear }} Copyright:
        <a class="text-reset fw-bold" href="/"
          >Conectec Tecnologia da Informação Eireli</a
        >
      </div>
      <div class="whatsapp-widget">
        <div v-if="showChat" class="chat-box">
          <div class="chat-header">
            <img src="/img/conectecLogo.png" alt="">
            <div class="chat-header-text">
              <h3>Carol</h3>
              <p>Consultora de Negócios da Conectec</p>
            </div>
            <span @click="openChatBalloon()" class="close-chat">&times;</span>
          </div>
          <!-- Animação de carregamento com três pontos -->
          <div v-if="loading" class="animation">
            <div class="typing-animation">
              <span></span><span></span><span></span>
            </div>
          </div>
          <div v-if="!loading" class="chat-body">
            <p><strong>Carol</strong></p>
            <p>Olá, como posso te ajudar hoje?</p>
            <span class="timestamp">{{ horaAtual }}:{{ minutos }}</span>
          </div>
          <div class="chat-footer">
            <button class="btn btn-primary btn-lg btn-block whatsapp-button">
              <i class="bi bi-whatsapp"></i>
              <a href="https://api.whatsapp.com/send?l=pt&phone=5512996267699&text=Novo%20Contato%20via%20Site" target="_blank">
                Clique aqui e tire suas dúvidas!
              </a>
            </button>
          </div>
        </div>
        <a          
          id="btn-whats"
          @click="openChatBalloon()"
        >
          <i style="margin-top: 16px" class="fa fa-whatsapp"></i>
        </a>
      </div>
      <!-- Copyright -->
    </footer>
    <!-- Footer -->
  </div>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      showChat: false,
      loading: true,
      horaAtual: new Date().getHours(),
      minutos: new Date().getMinutes()
    };
  },
  methods: {
    openChatBalloon() {
      this.showChat = !this.showChat;

      // Simula o tempo de carregamento antes de mostrar a mensagem
      if (this.showChat) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 3000); // Espera 2 segundos antes de exibir o texto
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0a67a8;
}

footer {
  width: 100vw !important;
  background-color: rgba(44, 62, 80, 0.1);
  margin-top: 100px;
}

.descriptition {
  color: #fff;
}

#follow-me {
  font-size: 18px;
  font-weight: 700;
}

.social-media img {
  height: 25px;
}

#btn-whats {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 14px;
  right: 10px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
  cursor: pointer;
  animation: 2.5s pulsar infinite forwards;
  transition: all .7
}

#btn-whats i {
  color: #fff;
}

.footer-adress {
  font-weight: 400;
  font-size: 14px;
}

.contaner {
  max-width: 100%;
}

.meios-pagamento img {
  height: 50px; /* Ajuste a altura conforme necessário */
}

.meios-pagamento #visa {
  height: 34px;
  width: 50px;
  background-color: #fff;
}

.meios-pagamento #dinersclub {
  height: 34px;
  width: 50px;
  background-color: #fff;
}

.meios-pagamento #boleto {
  height: 34px;
  width: 50px;
  background-color: #fff;
}

/* Estilo para o WhatsApp widget */
.whatsapp-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chat-box {
  width: 300px;
  background-color: #ece5dd;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: block;
  flex-direction: column;
  position: fixed;
  bottom: 80px;
  right: 20px;
}

/* Animação de pulsação (três pontos) */
.animation {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  width: 20%;
  height: 25px;
  margin: 25px 25px 25px 55px;
}

.typing-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.typing-animation span {
  width: 8px;
  height: 8px;
  margin: 0 3px;
  background-color: #333;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite both;
}

.typing-animation span:nth-child(1) {
  animation-delay: -0.32s;
}

.typing-animation span:nth-child(2) {
  animation-delay: -0.16s;
}

.typing-animation span:nth-child(3) {
  animation-delay: 0s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.chat-box .chat-header {
  display: flex;
  flex-direction: row;
  background-color: #0a7e7b;
  color: white;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  position: relative;
}

.chat-box .chat-header img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50px;
}

.chat-box .chat-header .chat-header-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chat-box .chat-header h3 {
  font-weight: bold;
  margin-right: 120px !important;
  color: #fff;
}

.chat-box .chat-header p {
  margin: -3px 0 0 15px;
  font-size: 0.7em;
  color: #fff;
}

.chat-box .close-chat {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.chat-body {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 15px 30px;
  font-family: "Montserrat", sans-serif;
  position: relative; /* Importante para o triângulo */
}

.chat-body:after {
    border-style: solid;
    border-inline-start-width: 0;
    border-inline-end-width: 30px;
    border-block-start-width: 0;
    border-block-end-width: 30px;
    border-inline-start-color: transparent;
    border-inline-end-color: var(--e-contact-buttons-message-bubble-bubble-bg);
    border-block-start-color: transparent;
    border-block-end-color: transparent;
    content: "";
    height: 0;
    position: absolute;
    inset-inline-start: -20px;
    top: 0;
    width: 0;
    color: #fff;
}

.chat-body p {
  margin: 0 0 10px;
  font-size: 0.85rem;
  font-family: "Montserrat", sans-serif;
  color: #333;
}

.chat-body strong {
  font-weight: bold;
  color: #333;
}

.timestamp {
  font-size: 0.8em;
  color: #888;
  text-align: right;
  display: block;
}


.chat-box .chat-footer {
  height: 60px;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
}

.chat-box .chat-footer a {
  font-size: 15px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.whatsapp-button {
  background-color: #25d366;
  color: white;
  border: none;
}

.whatsapp-button i {
  font-size: 15px;
  color: #fff;
}

.whatsapp-button:hover {
  background-color: #1eb349;
}

/* Hidden class for toggling chat visibility */
.hidden {
  display: none;
}

.visible {
  display: flex;
}

@keyframes pulsar {
    0%,100% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(-10px)
    }
}

@media (max-width: 768px) {
  .chat-body strong {
    display: flex;
    margin-left: 8px;
  }

  .chat-body {
    max-width: 100%;
  }

  .chat-body p {
    font-size: 0.80rem;
  }
  footer {
    margin-top: 0;
  }

  .text-center .ms-5 {
    margin: 50px;
  }
}
</style>