<template>
  <div
    class="modal fade"
    id="ModalMenu"
    tabindex="-1"
    role="dialog"
    aria-labelledby="MenuModalLabel"
    aria-hidden="true"
    data-backdrop="false"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="button-text">&times;</span>
          </button>
        </div>
        <div class="modal-body bg-light">
          <div class="container-menu">
            <router-link to="/" class="text-decoration">
              <span>Home</span>
            </router-link>
            <router-link to="/produtos" class="text-decoration">
              <span>Certificados</span>
            </router-link>
            <router-link to="/suporte" class="text-decoration">
              <span>Contato</span>
            </router-link>
            <router-link to="sobrenos" class="text-decoration">
              <span>Quem Somos</span>
            </router-link>
            <router-link to="/duvidas" class="text-decoration">
              <span>Dúvidas? Nós te ajudamos</span>
            </router-link>
            <router-link to="/requisitos" class="text-decoration">
              <span>Requisitos para videochamada</span>
            </router-link>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalMenu",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  background-color: #0a67a8;
}

.modal-header button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  border-radius: 50%;
  color: #ffff;
  border: 2px solid #ffffff;
  background-color: #0a67a8;
  margin-top: 2px;
  width: 40px;
  height: 40px;  
}

.modal-header h5 {
  color: #ffff;
}

.modal-body span {
  display: flex;
  justify-content: flex-start;
  padding: 5px;
  color: #0a67a8;
  border-bottom: 1px solid #cfcccc;
  cursor: pointer;
}

.modal-footer {
  background-color: #0a67a8;
}

strong {
  font-weight: bold;
}

.text-decoration {
  text-decoration: none;
}

@media (max-width: 390px) {
  .modal-header button {
    margin-right: -15px !important;
    width: 30px;
    height: 30px;
    border: none;
  }  
}
</style>
