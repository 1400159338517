<template>
    <div class="alert alert-danger justify-content-center alerta" role="alert">
        {{ msg }}
    </div>
    
</template>

<script>
export default {
    name: "AlertBox",
    props: {
        msg: String
    }
}
</script>

<style scoped>
.alerta {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10%;
    width: 30%;
    z-index: 1;
}

@media screen and (max-width: 768px) {
.alerta {
    margin: 0 1250px 0 0;
    width: 100%;
  }
}
</style>