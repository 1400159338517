<template>
  <div class="app-container">
    <MainNavbarVue :logo="logo_src" :alt="app_name" />
    <div class="product-list-container">
      <div class="product-list bg-light" style="border-radius: 10px">
        <ProductItemVue
          v-for="(produto, index) in produtosFiltrados"
          :key="index"
          :produto="produto"
          @click="refreshView"
        />
      </div>
    </div>
  </div>
  <div class="container">
    <h1>Dúvidas Frequentes</h1>
    <div class="list-duvidas">
      <ul class="list-group list-group-flush align-items-lg-start">
        <span>{{ questionTitle1 }}</span>
        <li class="">{{ answer1 }}</li>
      </ul>
      <ul class="list-group list-group-flush align-items-lg-start">
        <span>{{ questionTitle2 }}</span>
        <li class="">{{ answer2 }}</li>
        <p><strong>IMPORTANTE:</strong> Ao adquirir o certificado digital A3 conosco, é necessário que você já possua uma mídia externa a ser inserida em um computador com entrada USB. Essa mídia pode ser um token, ou um smart card, que é um cartão inteligente com leitora de certificado digital.</p>
      </ul>
      <ul class="list-group list-group-flush align-items-lg-start">
        <span>{{ questionTitle3 }}</span>
        <li class="">{{ answer3 }}</li>
        <p><strong>IMPORTANTE:</strong> Para realizar a validação online, é indispensável atender a pelo menos um dos requisitos mencionados anteriormente, caso os requisitos não sejam atendidos, a validação precisa ser realizada presencialmente. </p>
      </ul>
      <ul class="list-group list-group-flush align-items-lg-start">
        <span>{{ questionTitle4 }}</span>
        <li class="">{{ answer4 }}</li>
      </ul>
      <ul class="list-group list-group-flush align-items-lg-start">
        <span>{{ questionTitle5 }}</span>
        <p><strong>Todos tipos de certificado digital é obrigatório o envio de uma destas opções de documento com foto:</strong></p>
        <li class="">{{ answer5 }}</li>
        <li class="">{{ answer6 }}</li>
        <li class="">{{ answer7 }}</li>
        <li class="">{{ answer8 }}</li>
        <p><strong>Se seu tipo de certificado digital for e-CNPJ incluir a documentação da empresa que pode ser:</strong></p>
        <li class="">{{ answer9 }}</li>
        <li class="">{{ answer10 }}</li>
        <li class="">{{ answer11 }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import MainNavbarVue from '@/components/MainNavbar.vue';
import ProductItemVue from "../components/ProductItem.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "DuvidasTopics",
  data() {
    return {
      logo_src: "/img/conectec_logo.svg",
      app_name: "Conectec",
      questionTitle1: "O que é um Certificado Digital?",
      answer1: "O Certificado Digital é uma assinatura com validade jurídica que garante proteção às transações eletrônicas e outros serviços via internet, permitindo que pessoas e empresas se identifiquem e assinem digitalmente de qualquer lugar do mundo com mais segurança e agilidade.",
      questionTitle2: "Qual a diferença do modelo A1 para o A3?",
      answer2: "Certificados digitais A1 são certificados digitais válidos por 1 (um) ano, que ficam armazenados no próprio computador do cliente, por isso, não são portáveis. Recomendamos ao cliente que esse tipo de certificado tenha uma cópia de segurança (backup) em disquete, ou em outra mídia portável. Certificados digitais A3 são certificados digitais portáveis, que são armazenados em cartão inteligente e utilizados por meio de uma leitora que permite o acesso ao certificado em vários computadores diferentes. Apresentam nível de segurança superior.",
      questionTitle3: "Quais requisitos para realizar o atendimento por videochamada?",
      answer3: "Possuir CNH (Carteira de habilitação Nacional) com data de emissão a partir de 2017 Ou Ter feito certificado digital anteriormente de forma presencial a partir de 2018, independente do tipo de certificado.",
      questionTitle4: "O Certificado Digital é compatível com qualquer sistema operacional?",
      answer4: "Os Certificados Digitais do modelo A1 são compatíveis para emissão com os seguintes sistemas operacionais instalados no computador: Windows ou Linux. Já a instalação pode ser feita em todos sistemas operacionais.",
      questionTitle5: "Qual documentação é necessário enviar?",
      answer5: "CNH - Carteira Nacional de Habilitação",
      answer6: "RG - Cédula de identificação",
      answer7: "Documento de classe profissional",
      answer8: "Passaporte",
      answer9: "Contrato social devidamente registrado em órgão competente",
      answer10: "Redesim com código de autenticidade",
      answer11: "Estatuto | Ata de eleição devidamente registrados em órgão competente quando aplicável"
    }
  },
  components: {
    MainNavbarVue,
    ProductItemVue
  },
  computed: {
    ...mapGetters(["produtosFiltrados"]),
  },
  methods: {
    ...mapActions(["clearFiltro"]),
    navBarAnimation991px() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.clearFiltro();
    next();
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #0a67a8;
  margin-top: 180px;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.product-list-container {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 190px;
  margin-left: -80px;
  z-index: 9999;
}

.product-list-container.sticky {
  margin-top: 170px;
  transition: 0.7s;
}

.product-list {
  position: fixed;
  width: 48%;
}

span {
  font-weight: 700;
  font-size: 20px;
}

.list-duvidas {
  margin: 30px 100px 0 100px;
  text-align: justify;
  font-size: 14px;
}

.list-duvidas ul {
  margin-bottom: 50px;
  list-style: none;
  color: #475660;
}

.list-duvidas p {
  margin-top: 20px;
}

strong {
  font-weight: bold;
  color: #475660;
}

@media (max-width: 991px) {
  .product-list-container {
    margin-top: 210px;
  }

  .product-list-container.sticky {
    margin-top: 160px;
  }

  .product-list {
    margin: 0 0 0 150px;
  }
}

@media (max-width: 768px) {
  .list-duvidas {
    width: 100%;
  }

  .product-list {
    width: 100%;
    margin: 10px 0 0 100px;
  }
}

@media (max-width: 568px) {
  .product-list {
    width: 100%;
    margin-top: 20px;
  }
}
</style>