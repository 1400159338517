import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '../src/store';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import VueGtag from 'vue-gtag';

library.add(fas);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);

app
.use(router)
.use(store)
.use(VueGtag, {
    config: { id: "G-G86BH060BY"}
}, router)
.mount('#app')
