<template>
  <div class="product-item" @click="viewProduct(produto)">
    <img :src="produto.image" alt="Product Image" />
    <div class="product-info">
      <h3>{{ produto.name }}</h3>
      <p>R$ {{ produto.price }} | {{ produto.installments }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    produto: Object,
  },
  methods: {
    viewProduct(produto) {
      this.$router.push({
        name: "ProdutoDetail",
        params: { id: produto.id },
        query: {
          name: produto.name,
          price: produto.price,
          pix: produto.pix,
          image: produto.image,
          priceInstallments: produto.priceInstallments,
          installments: produto.installments,
        },
      });
    },
  },
};
</script>

<style scoped>
.product-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.product-item img {
  width: 10%;
  height: 10%;
  margin-right: 10px;
}

.product-info h3 {
  display: flex;
  margin: 0;
  font-size: 16px;
}

.product-info p {
  margin: 5px 0 0;
  color: #666;
}
</style>
