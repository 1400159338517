import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ProdutoDetail from '../views/ProdutoDetail.vue';
import DpnView from '../views/DpnView.vue';
import SuporteView from '../views/SuporteView.vue';
import ProdutosView from '../views/ProdutosView.vue';
import DuvidasView from '../views/DuvidasView.vue';
import RequisitosView from '../views/RequisitosView.vue';
import SobreNos from '../views/SobreNos.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Conectec Certificação Digital'
    }
  },
  {
    path: '/produto/:id',
    name: 'ProdutoDetail',
    component: ProdutoDetail,
    props: route => ({
      id: route.params.id,
      name: route.params.name,
      price: route.params.price,
      pix: route.params.pix,
      image: route.params.image,
      priceInstallments: route.params.priceInstallments,
      installments: route.params.installments
    })
  },
  {
    path: '/dpn',
    name: 'DpnView',
    component: DpnView,
    meta: {
      title: 'Declaração de práticas de negócio'
    }
  },
  {
    path: '/suporte',
    name: 'SuporteView',
    component: SuporteView,
    meta: {
      title: 'Suporte - Conectec Certificação Digital'
    }
  },
  {
    path: '/produtos',
    name: 'ProdutosView',
    component: ProdutosView,
    meta: {
      title: 'Nossos Produtos'
    }
  },
  {
    path: '/duvidas',
    name: 'DuvidasView',
    component: DuvidasView,
    meta: {
      title: 'Dúvidas Frequentes'
    }
  },
  {
    path: '/requisitos',
    name: 'RequisitosView',
    component: RequisitosView,
    meta: {
      title: 'Requisitos de Videochamada'
    }
  },
  {
    path: '/sobrenos',
    name: 'SobreNos',
    component: SobreNos,
    meta: {
      title: 'Sobre Nós'
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Conectec Certificação Digital';
  next();
});

export default router;
