<template>
  <div class="app-container">
    <NavBar :logo="logo_src" :alt="app_name" />
    <div class="product-list-container">
      <div class="product-list bg-light" style="border-radius: 10px">
        <ProductItemVue
          v-for="(produto, index) in produtosFiltrados"
          :key="index"
          :produto="produto"
          @click="refreshView"
        />
      </div>
    </div>
  </div>
  <section class="h-100 gradient-custom">
    <ModalPrice
      :preco="productData.priceInstallments"
      :precoSemDesconto="productData.price"
    />
    <div class="container py-5">
      <div class="row d-flex justify-content-center my-4">
        <div class="col-md-8">
          <div class="card mb-4 border-0">
            <div class="card-body">
              <!-- Single item -->
              <div class="row">
                <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                  <!-- Image -->
                  <div
                    class="bg-image hover-overlay hover-zoom ripple rounded"
                    data-mdb-ripple-color="light"
                  >
                    <img
                      :src="productData.image"
                      class="product-image"
                      style="width: 700px"
                      alt="Blue Jeans Jacket"
                    />
                  </div>
                  <!-- Image -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="product-name">
            <p class="mb-0">{{ productData.name }}</p>
          </div>
          <div class="card mb-4 border-0">
            <div class="card-body d-flex mb-auto py-3 border-0">
              <span class="mb-0">{{ productData.name }}</span>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0"
                  id="list-group-item-price"
                >
                  R$ {{ productData.price }}
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center px-0 border-bottom-0"
                  id="list-group-item-pix"
                >
                  {{ productData.pix }}
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center px-0 border-bottom-0"
                  id="list-group-item-ou"
                >
                  ou
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3"
                  id="list-group-item-installments"
                >
                  <div class="d-flex justify-content-center align-items-center">
                    <i class="bi bi-credit-card-2-front me-2 fs-4"></i>
                    <span>{{ productData.installments }}</span>
                  </div>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center border-0 px-0"
                  id="list-group-detail"
                >
                  <div class="d-flex justify-content-center align-items-center">
                    <span
                      data-toggle="modal"
                      data-target="#priceModal"
                      id="list-group-span"
                      >Ver mais detalhes</span
                    >
                  </div>
                </li>
              </ul>

              <button
                type="button"
                data-mdb-button-init
                data-mdb-ripple-init
                class="btn btn-primary btn-lg btn-block me-5"
              >
                <a
                  href="https://api.whatsapp.com/send?l=pt&phone=5512996267699&text=Novo%20Contato%20via%20Site"
                  target="_blank"
                >
                  Dúvidas? Chame no Whatsapp
                </a>
                <i class="bi bi-whatsapp"></i>
              </button>
            </div>
          </div>
          <div class="requirements-video">
            <div class="requirements-label">
              <i class="bi bi-shield-check"></i>
              <span>Requisitos da videoconferência</span>
            </div>
            <div class="requirements-content">
              <p>
                Se você já teve um e-CPF ou e-CNPJ emitido presencialmente
                pós-2018, ou quem porta CNH emitida pós-2017.
              </p>
            </div>
          </div>

          <div class="description-agile">
            <div class="description-label">
              <i class="bi bi-stopwatch-fill"></i>
              <span>Agilidade</span>
            </div>
            <div class="description-content">
              <p>
                As emissões remotas como a videoconferência, são bem rápidas, pois não há necessidade de
                esperar em uma fila. A videoconferência pode ser realizada com
                horário marcado e, em alguns casos, as validações podem durar
                menos de 15 minutos.
              </p>
            </div>
          </div>
        </div>
        <div class="description-e-cpfCnpj">
          <span id="certificate-type">{{ tipoCertificado }}</span>
          <p>
            {{ descricaoTitulo }}
          </p>
          <p>Confira algumas utilizações do {{ tipoCertificado }}:</p>

          <div class="list-use-ecpfCnpj">
            <ul class="list-group list-group-flush align-items-lg-start">
              <span>{{ UsabilidadeTitulo1 }}</span>
              <li class="">
                {{ UsabilidadeDescricao1 }}
              </li>
            </ul>
            <ul class="list-group list-group-flush align-items-lg-start">
              <span>{{ UsabilidadeTitulo2 }}</span>
              <li class="">
                {{ UsabilidadeDescricao2 }}
              </li>
            </ul>
            <ul class="list-group list-group-flush align-items-lg-start">
              <span>{{ UsabilidadeTitulo3 }}</span>
              <li class="">
                {{ UsabilidadeDescricao3 }}
              </li>
            </ul>
            <ul class="list-group list-group-flush align-items-lg-start">
              <span>{{ UsabilidadeTitulo4 }}</span>
              <li class="">
                {{ UsabilidadeDescricao4 }}
              </li>
            </ul>
            <ul class="list-group list-group-flush align-items-lg-start">
              <span>{{ UsabilidadeTitulo5 }}</span>
              <li class="">
                {{ UsabilidadeDescricao5 }}
              </li>
            </ul>
            <ul class="list-group list-group-flush align-items-lg-start">
              <span>{{ UsabilidadeTitulo6 }}</span>
              <li class="">
                {{ UsabilidadeDescricao6 }}
              </li>
            </ul>
            <ul class="list-group list-group-flush align-items-lg-start">
              <span>{{ UsabilidadeTitulo7 }}</span>
              <li class="">
                {{ UsabilidadeDescricao7 }}
              </li>
            </ul>
            <ul class="list-group list-group-flush align-items-lg-start">
              <span>{{ UsabilidadeTitulo8 }}</span>
              <li class="">
                {{ UsabilidadeDescricao8 }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ModalPrice from "../components/ModalPrice.vue";
import NavBar from "../components/MainNavbar.vue";
import ProductItemVue from "../components/ProductItem.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "ProdutoDetail",
  components: {
    ModalPrice,
    NavBar,
    ProductItemVue,
  },
  props: {
    id: String,
    name: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      default: "",
    },
    pix: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    priceInstallments: {
      type: String,
      default: "",
    },
    installments: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      productData: {
        name: this.name || this.$route.query.name,
        price: this.price || this.$route.query.price,
        pix: this.pix || this.$route.query.pix,
        image: this.image || this.$route.query.image,
        priceInstallments:
          this.priceInstallments || this.$route.query.priceInstallments,
        installments: this.installments || this.$route.query.installments,
      },
      tipoCertificado: "",
      descricaoTitulo: "",
      UsabilidadeTitulo1: "",
      UsabilidadeDescricao1: "",
      UsabilidadeTitulo2: "",
      UsabilidadeDescricao2: "",
      UsabilidadeTitulo3: "",
      UsabilidadeDescricao3: "",
      UsabilidadeTitulo4: "",
      UsabilidadeDescricao4: "",
      UsabilidadeTitulo5: "",
      UsabilidadeDescricao5: "",
      UsabilidadeTitulo6: "",
      UsabilidadeDescricao6: "",
      UsabilidadeTitulo7: "",
      UsabilidadeDescricao7: "",
      UsabilidadeTitulo8: "",
      UsabilidadeDescricao8: "",
      logo_src: "/img/conectec_logo.svg",
      app_name: "Conectec",
    };
  },
  computed: {
    ...mapGetters(["produtosFiltrados"]),
  },
  methods: {
    ...mapActions(["clearFiltro"]),
    alterarDescricaoDeAcordoComTipoDeCertificado(param) {
      let resultKey;

      if (param === "Certificado Digital e-CNPJ A1") {
        resultKey = "ecnpja1";
      } else if (param === "Certificado Digital e-CNPJ A3 Sem mídia") {
        resultKey = "ecnpja3";
      } else if (param === "Certificado Digital e-CPF A1") {
        resultKey = "ecpfa1";
      } else if (param === "Certificado Digital MEI A1") {
        resultKey = "mei-A1";
      } else if (param === "Certificado Digital e-CPF A3 - Sem mídia") {
        resultKey = "A3SemMidia";
      }

      if (resultKey) {
        fetch("/dados.json")
          .then((resp) => {
            if (!resp.ok) {
              throw new Error("Erro ao carregar o JSON: " + resp.statusText);
            }
            return resp.json();
          })
          .then((data) => {
            const resultData = data[resultKey];

            if (resultData && resultData.length > 0) {
              const firstItem = resultData[0]; // Acessa o primeiro objeto da lista

              this.tipoCertificado = firstItem.titulo;
              this.descricaoTitulo = firstItem.descricaoTitulo;
              this.UsabilidadeTitulo1 = firstItem.UsabilidadeTitulo1;
              this.UsabilidadeDescricao1 = firstItem.UsabilidadeDescricao1;
              this.UsabilidadeTitulo2 = firstItem.UsabilidadeTitulo2;
              this.UsabilidadeDescricao2 = firstItem.UsabilidadeDescricao2;
              this.UsabilidadeTitulo3 = firstItem.UsabilidadeTitulo3;
              this.UsabilidadeDescricao3 = firstItem.UsabilidadeDescricao3;
              this.UsabilidadeTitulo4 = firstItem.UsabilidadeTitulo4;
              this.UsabilidadeDescricao4 = firstItem.UsabilidadeDescricao4;
              this.UsabilidadeTitulo5 = firstItem.UsabilidadeTitulo5;
              this.UsabilidadeDescricao5 = firstItem.UsabilidadeDescricao5;
              this.UsabilidadeTitulo6 = firstItem.UsabilidadeTitulo6;
              this.UsabilidadeDescricao6 = firstItem.UsabilidadeDescricao6;
              this.UsabilidadeTitulo7 = firstItem.UsabilidadeTitulo7;
              this.UsabilidadeDescricao7 = firstItem.UsabilidadeDescricao7;
              this.UsabilidadeTitulo8 = firstItem.UsabilidadeTitulo8;
              this.UsabilidadeDescricao8 = firstItem.UsabilidadeDescricao8;
            } else {
              alert("Nenhum dado encontrado para a chave:", resultKey);
            }
          })
          .catch((error) => {
            alert("Erro ao carregar o JSON:", error);
          });
      } else {
        alert("Parâmetro inválido:", param);
      }
    },
    navBarAnimation() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    navBarAnimation991px() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    refreshView() {
      setTimeout(() => {
        location.reload();
      }, 1000);
    },
  },
  mounted() {
    const param = this.productData.name; // Definir o parâmetro que deseja usar aqui
    this.alterarDescricaoDeAcordoComTipoDeCertificado(param);
    this.navBarAnimation();
    this.navBarAnimation991px();
    document.title = this.productData.name;
  },
  beforeRouteLeave(to, from, next) {
    this.clearFiltro();
    next();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.container {
  margin-top: 145px;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.product-list-container {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 190px;
  margin-left: -80px;
  z-index: 9999;
}

.product-list-container.sticky {
  margin-top: 170px;
  transition: 0.7s;
}

.product-list {
  position: fixed;
  width: 48%;
}

span {
  font-weight: 700;
  font-size: 14px;
}

.mb-0 {
  font-weight: 700;
  font-size: 21px;
}

#list-group-item-price {
  font-weight: 700;
  font-size: 26px;
  color: #0a67a8;
  margin-top: -20px;
}

#list-group-item-pix {
  font-weight: 700;
  font-size: 16px;
  margin-top: -10px;
}

#list-group-item-ou {
  margin-top: -15px;
}

#list-group-item-installments {
  margin-top: -10px;
  font-weight: 700;
  margin-top: -15px;
  color: #0a67a8;
}

#list-group-span {
  margin-top: -50px;
  font-size: 10px;
  color: #0a67a8;
  cursor: pointer;
}

#list-group-span:hover {
  opacity: 50%;
}

.product-image {
  margin-left: -50px;
  margin-top: -10px;
}

.product-name {
  display: flex;
  justify-content: flex-start;
  margin-top: -35px;
  margin-left: 18px;
}

.product-name p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

button {
  font-size: 14px;
}

button a {
  color: #fff;
  text-decoration: none;
}

.requirements-video {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: -60px;
}

.requirements-label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.requirements-label i {
  font-size: 17px;
}

.requirements-content {
  margin-left: 13px;
  font-size: 14px;
}

.requirements-content p {
  margin-left: 95px;
  font-size: 13px;
  text-align: justify;
}

.description-agile {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: -60px;
}

.description-label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: -155px;
}

.description-label i {
  font-size: 17px;
}

.description-content {
  margin-left: 13px;
  font-size: 14px;
}

.description-content p {
  margin-left: 95px;
  font-size: 13px;
  text-align: justify;
}

#certificate-type {
  font-size: 16px;
}

.description-e-cpfCnpj p {
  text-align: justify;
  font-size: 14px;
}

.description-e-cpfCnpj span {
  font-size: 14px;
}

.description-e-cpfCnpj {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.list-use-ecpfCnpj {
  text-align: justify;
  font-size: 14px;
}

.list-use-ecpfCnpj ul {
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .product-image {
    max-width: 100%;
  }

  button {
    width: 100%;
  }

  .product-list-container {
    margin: 220px 0 0 50px;
  }
}

@media (max-width: 768px) {
  .product-list-container {
    margin: 230px 90px 0 0;
  }
  
  .product-list {
    width: 100%;
    margin-left: 100px;
  }

  .product-list-container.sticky {
    margin-top: 180px;
  }

  .product-image {
    max-width: 100%;
    margin-left: 10px;
    margin-top: -50px;
  }

  .product-name {
    margin-top: 20px;
  }
}

@media (max-width: 568px) {
  .product-list-container {
    margin-top: 250px;
  }
  .product-list-container.sticky {
    margin-top: 192px;
  }

}
</style>
