<template>
  <div
    class="modal fade"
    id="priceModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="priceModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="priceModalLabel">Meios de Pagamento</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="button-text">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <span>Cartões de Crédito</span>
          </div>
          <div>
            <span
              >Total em 1 parcela:&nbsp;<strong>R$ {{ preco }}</strong
              >&nbsp;com todos os cartões.</span
            >
            <br />
            <span><strong>Ou pague em:</strong>&nbsp;</span>
          </div>

          <table
            class="table table-striped"
            v-for="price in prices"
            :key="price.id"
          >
            <tbody>
              <tr>
                <th scope="row">
                  {{ price.id }} X de&nbsp;<strong>{{ price.price }}</strong
                  >&nbsp;{{ price.additionalText }}
                </th>
                <td><strong>Total</strong>&nbsp;{{ price.total }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <section
          class="d-flex justify-content-center justify-content-lg-between p-4"
        >
          <div class="meios-pagamento">
            <span href="" class="me-4 text-reset">
              <img src="/img/visa.svg" alt="" />
            </span>
            <span href="" class="me-4 text-reset">
              <img src="/img/mastercard.svg" alt="" />
            </span>
            <span href="" class="me-4 text-reset">
              <img src="/img/american-express.svg" alt="" />
            </span>
            <span href="" class="me-4 text-reset">
              <img src="/img/dinersclub.svg" alt="" />
            </span>
            <span href="" class="me-4 text-reset">
              <img src="/img/elo.svg" alt="" />
            </span>
            <span href="" class="me-4 text-reset">
              <img src="/img/hipercard.svg" alt="" />
            </span>
          </div>
        </section>
        
        <section class="payment-boleto">
          <span>Boleto</span>
          <div class="payment-value">
            <span href="" class="me-4 text-reset">
              <img src="/img/boleto.svg" alt="" />
            </span>
            <span>Total:&nbsp;<strong>R$ {{ preco }}</strong>&nbsp;</span>
          </div>
        </section>

        <section class="payment-pix">
          <span>Pix</span>
          <div class="payment-pix-value">
            <span href="" class="me-4 text-reset">
              <img src="/img/pix.svg" alt="" style="width: 60px" />
            </span>
            <span>
              <strong>10% de desconto</strong>&nbsp;pagando com Pix
            </span>
            <span>
              Total: <del>{{ preco }}</del>&nbsp;&nbsp;&nbsp;<strong>{{ precoSemDesconto }}</strong>
            </span>
          </div>
        </section>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalPrice",
  props: ["preco", "precoSemDesconto"],
  data() {
    return {
      prices: [
        {
          id: 1,
          price: parseFloat(
            this.formatarPreco(this.preco) / 100
          ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
          total: parseFloat(this.preco).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
          additionalText: "sem juros",
        },
        {
          id: 2,
          price: parseFloat(
            this.formatarPreco(this.preco) / 100 / 2
          ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
          total: parseFloat(this.preco).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
          additionalText: "sem juros",
        },
        {
          id: 3,
          price: parseFloat(
            this.formatarPreco(this.preco) / 100 / 3
          ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
          total: parseFloat(this.preco).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
          additionalText: "sem juros",
        },
        {
          id: 4,
          price: (this.acrescentarJuros(this.preco, 0.12) / 4).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          total: this.acrescentarJuros(this.preco, 0.12).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          additionalText: "",
        },
        {
          id: 5,
          price: (this.acrescentarJuros(this.preco, 0.1258) / 5).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          total: this.acrescentarJuros(this.preco, 0.1258).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          additionalText: "",
        },
        {
          id: 6,
          price: (this.acrescentarJuros(this.preco, 0.13) / 6).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          total: this.acrescentarJuros(this.preco, 0.13).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          additionalText: "",
        },
        {
          id: 7,
          price: (this.acrescentarJuros(this.preco, 0.1358) / 7).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          total: this.acrescentarJuros(this.preco, 0.1358).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          additionalText: "",
        },
        {
          id: 8,
          price: (this.acrescentarJuros(this.preco, 0.14) / 8).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          total: this.acrescentarJuros(this.preco, 0.14).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          additionalText: "",
        },
        {
          id: 9,
          price: (this.acrescentarJuros(this.preco, 0.1458) / 9).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          total: this.acrescentarJuros(this.preco, 0.1458).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          additionalText: "",
        },
        {
          id: 10,
          price: (this.acrescentarJuros(this.preco, 0.15) / 10).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          total: this.acrescentarJuros(this.preco, 0.15).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
        },
        {
          id: 11,
          price: (
            this.acrescentarJuros(this.preco, 0.1558) / 11
          ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
          total: this.acrescentarJuros(this.preco, 0.1558).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
        },
        {
          id: 12,
          price: (this.acrescentarJuros(this.preco, 0.16) / 12).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
          total: this.acrescentarJuros(this.preco, 0.16).toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          ),
        },
      ],
    };
  },
  methods: {
    formatarPreco(prices) {
      let valor = prices.replace(/[.,]/g, "");
      return valor;
    },
    acrescentarJuros(price, percentual) {
      let preco = this.formatarPreco(price);
      let valor = (parseFloat(preco) * percentual + parseFloat(preco)) / 100;
      let total = valor;
      return total;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.modal-header {
  background-color: #0a67a8;
}

#priceModalLabel {
  color: #ffff;
}

.modal-header button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  border-radius: 50%;
  color: #ffff;
  border: 2px solid #ffffff;
  background-color: #0a67a8;
  margin-top: 2px;
  width: 40px;
  height: 40px;
  margin-left: 850px;
}

.modal-body span {
  display: flex;
  justify-content: flex-start;
}

tbody tr th {
  text-align: left;
}

tbody tr td {
  text-align: right;
}

strong {
  font-weight: bold;
}

.meios-pagamento {
    margin-top: -40px;
}

.meios-pagamento img {
  height: 50px;
  border-radius: 25%;
}

.payment-boleto {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
}

.payment-value {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.payment-boleto img {
  height: 50px;
  border-radius: 25%;
}

.payment-pix {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.payment-pix-value {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.payment-pix img {
  height: 50px;
  border-radius: 25%;
}
</style>