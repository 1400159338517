<template>
  <div class="app-container">
    <NavBar :logo="logo_src" :alt="app_name" />
    <div class="product-list-container">
      <div class="product-list bg-light" style="border-radius: 10px">
        <ProductItemVue
          v-for="(produto, index) in produtosFiltrados"
          :key="index"
          :produto="produto"
        />
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <h1>Declaração de Práticas de Negócio</h1>
    <div class="dpn-doc">
      <iframe
        src="https://docs.google.com/document/d/1iRWh6d9abis3SVto32sa1Hqp9UM1A2-z/edit"
      ></iframe>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/MainNavbar.vue";
import ProductItemVue from "../components/ProductItem.vue";
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

export default {
  name: "DpnView",
  data() {
    return {
      logo_src: "/img/conectec_logo.svg",
      app_name: "Conectec",
    };
  },
  computed: {
    ...mapGetters(['produtosFiltrados'])
  },
  components: {
    NavBar,
    ProductItemVue
  },
  methods: {
    ...mapActions(['clearFiltro']),
    navBarAnimation() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    navBarAnimation991px() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
  },
  mounted() {
    this.navBarAnimation();
    this.navBarAnimation991px();
  },
  beforeRouteLeave(to, from, next) {
    this.clearFiltro();
    next();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

.container h1 {
  margin-top: 200px;
  margin-bottom: 3.125rem;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
}

.product-list-container {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 190px;
  margin-left: -80px;
  z-index: 9999;
}

.product-list-container.sticky {
  margin-top: 170px;
  transition: 0.7s;
}

.product-list {
  position: fixed;
  width: 48%;
}

.dpn-doc iframe {
  width: 100%;
  height: 75rem;
  margin-bottom: 3.125rem;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }

  .product-list-container {
      margin: 220px 0 0 50px;
  }

  .product-list-container.sticky {
      margin-top: 160px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }
  
  .container h1 {
    font-size: 1.625rem;
    max-width: 100vh;
  }

  .product-list-container {
      margin-top: 230px;
  }

  .product-list-container.sticky {
      margin-top: 180px;
  }

  .product-list {
    width: 100%;
    margin-left: 100px;
  }

  .dpn-doc iframe {
    max-width: 100vh;
    max-height: 100vh;
  }
}

@media (max-width: 568px) {
  .product-list-container {
      margin: 255px 90px 0 0;
  }

  .product-list-container.sticky {
      margin-top: 195px;
  }
  
}

@media (max-width: 390px) {
  .container {
    max-width: 100%;
  }

  .container h1 {
    font-size: 1rem;
  }

  .dpn-doc iframe {
    width: 100vh;
  }
}
</style>