<template>
  <div class="app-container">
    <Navbar :logo="logo_src" :alt="app_name" />
    <div class="product-list-container">
      <div class="product-list bg-light" style="border-radius: 10px">
        <ProductItemVue
          v-for="(produto, index) in produtosFiltrados"
          :key="index"
          :produto="produto"
        />
      </div>
    </div>
  </div>
  
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
    <!--<ol class="carousel-indicators">
      <li
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="0"
        class="active"
      ></li>
      <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
      <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
    </ol>-->
    <div class="carousel-inner"
    v-for="produto in produtosCard"
    :key="produto.id"
    :produto="produto"
    >
      <div class="carousel-item active" @click="viewProduct(produto)" v-if="produto.id === 1">
        <img v-if="windowWidth >= 768" class="d-block w-100" src="/img/banner_consulti.jpg" alt="First slide" />
        <img v-else class="d-block w-100" src="/img/banner-consulti-768.png" alt="First slide" />
      </div>
      <!--<div class="carousel-item">
        <img class="d-block w-100" src="/img/banner_2.jpg" alt="Second slide" />
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="/img/banner_3.jpg" alt="Third slide" />
      </div>-->
    </div>
    <!--<a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </a>-->
  </div>
  <div class="row d-flex justify-content-center align-items-center">
    <swiper class="informative-swiper"
      :slides-per-view="slidesPerViewBanner"

      :breakpoints="{
        768: {
          watchOverflow: false
        },
        1200: {
          watchOverflow: true
        }
      }"
    >
      <swiper-slide class="informative-swiper-slide">
        <router-link to="/produtos" class="router-slide">
          <i class="bi bi-percent"></i>
          <div class="informative-oferta">
            <span>Pediu oferta?</span>
            <p>Por aqui, você encontra<br>certificados digitais com<br>os melhores preços do Brasil!</p>
          </div>
        </router-link>
      </swiper-slide>
      <swiper-slide class="informative-swiper-slide">
        <a href="https://api.whatsapp.com/send?l=pt&phone=5512996470999&text=Novo%20Contato%20via%20Site"
        target="_blank"
        class="router-slide">
          <i class="bi bi-whatsapp"></i>
          <div class="informative-oferta">
            <span>Ainda em dúvida?<br>Fale com a gente! :)</span>
            <p>Estamos aqui para te<br>guiar na escolha do certificado<br>ideal para as suas necessidades!</p>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide class="informative-swiper-slide">
        <router-link to="#" class="router-slide router-house-laptop">
          <i class="bi bi-house"></i>
          <i class="bi bi-laptop"></i>
          <div class="informative-oferta">
            <span>Com a gente, os certificados<br>são emitidos através de<br>videoconferência ou presencial</span>
            <p>Você escolhe a melhor<br>forma para sua comodidade.</p>
          </div>
        </router-link>
      </swiper-slide>
      <swiper-slide class="informative-swiper-slide">
        <router-link to="#" class="router-slide">
          <i class="bi bi-info-circle"></i>
          <div class="informative-oferta">
            <span>Suporte</span>
            <p>Aqui você vai encontrar os<br>programas para instalação<br>do seu Certificado Digital.</p>
          </div>
        </router-link>
      </swiper-slide>
    </swiper>
  </div>  

  <div class="container">
    <h1>Destaques</h1>
    <div
      class="row d-flex justify-content-center align-items-center card-space"
      style="margin-left: 40px"
    >
      <div class="col rounded mb-3">
        <swiper
          ref="mySwiper"
          :slides-per-view="slidesPerViewValue"

          :loop="false"
          :freeMode="false"
          :resistanceRatio="0"
          :initial-slide="initialSlideValue"
          :watchOverflow="true"
          :allow-slide-next="allowSlideNext"
          :allow-slide-prev="allowSlidePrev"
          @slideChange="onSlideChange"
          @reachEnd="onReachEnd"
          @reachBeginning="onReachBeginning"
          
          class="swiper-container-full"
        >
          <swiper-slide
            v-for="(produto, index) in produtosCard"
            :key="index"
            :produto="produto"
            class="swiper-slide-item"
          >
            <div class="card h-100" @click="viewProduct(produto)" v-if="produto.id < 4">
              <div :class="produto.cardText === '' ? 'empty-text' : 'card-destaque'">
                {{ produto.cardText }}
              </div>
              <div
                    :class="
                      produto.cardHighlight === '' ? 'none' : 'card-highlight'
                    "
                  ></div>
              <div class="text-center">
                <img
                  class="card-img-top mx-auto"
                  :src="produto.image"
                  alt="Card image cap"
                />
              </div>
              <div class="card-body boder">
                <p class="card-text">Certificado Digital e-CNPJ A3</p>
                <h5 class="card-title">R$ {{ produto.price }}</h5>
                <span class="card-title">{{ produto.pix }}</span>
                <p class="card-text">
                  <small class="text-muted"
                    >ou R$ {{ produto.priceInstallments }} a prazo</small
                  >
                </p>
                <p>
                  <small class="text-muted">{{ produto.installments }}</small>
                </p>
              </div>
              <div class="card-buttons-container">
                <div class="card-buttons">
                  <button
                    type="button"
                    data-mdb-button-init
                    data-mdb-ripple-init
                    class="btn btn-primary btn-lg btn-block me-5"
                  >
                    <i class="bi bi-eye"></i>
                    Espiar
                  </button>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import SearchBarVue from '@/components/SearchBar.vue';
import ProductItemVue from "@/components/ProductItem.vue";
import Navbar from "../components/MainNavbar.vue";
import { mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

export default {
  name: "HomeView",
  components: {
    Navbar,
    //SearchBarVue,
    ProductItemVue,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      filtro: "",
      isVisible: false,
      spaceBetweenValue: -700,
      slidesPerViewValue: 3,
      slidesPerViewBanner: 4,
      initialSlideValue: 0,
      allowSlideNext: true,
      allowSlidePrev: true,
      swiperInstance: null,
      windowWidth: window.innerWidth,
      logo_src: "/img/conectec_logo.svg",
      app_name: "Conectec",
    };
  },
  computed: {
    /*produtosFiltrados() {
      if (!this.filtro) {
        return [];
      }
      return this.produtos.filter((produto) =>
        produto.name.toLowerCase().includes(this.filtro.toLowerCase())
      );
    }*/
    ...mapGetters(["produtosFiltrados"]),
    ...mapGetters(["produtosCard"]),
  },
  methods: {
    ...mapActions(["clearFiltro"]),
    viewProduct(produto) {
      this.$router.push({
        name: "ProdutoDetail",
        params: { id: produto.id },
        query: {
          name: produto.name,
          price: produto.price,
          pix: produto.pix,
          image: produto.image,
          priceInstallments: produto.priceInstallments,
          installments: produto.installments,
        },
      });
    },
    atualizarFiltro(novoFiltro) {
      this.filtro = novoFiltro;
      if (novoFiltro) {
        document.querySelector(".product-list-container").style.marginTop = "80px";
      } else {
        document.querySelector(".product-list-container").style.marginTop = "140px";
      }
    },
    navBarAnimation() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    navBarAnimation991px() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    handleResizeBannerInformation() {
      if (window.innerWidth <= 390) {
        this.slidesPerViewBanner = 1;
      }
      else if (window.innerWidth <= 568) {
        this.slidesPerViewBanner = 1;
      }
      else if (window.innerWidth <= 768) {
        this.slidesPerViewBanner = 2;
      }
      else {
        this.slidesPerViewBanner = 4;
      }
      this.updateSwiper(); // Atualiza o Swiper após redimensionar
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      // Ajusta o valor de spaceBetween baseado na resolução da tela
      if (window.innerWidth <= 390) {
        this.slidesPerViewValue = 1.5;
      } else if (window.innerWidth <= 768) {
        this.spaceBetweenValue = 10;
        this.slidesPerViewValue = 3;
      } else if (window.innerWidth <= 991) {
        this.spaceBetweenValue = 20;
        this.slidesPerViewValue = 3;
      } else {
        this.spaceBetweenValue = 30;
        this.slidesPerViewValue = 3;
      }
      this.updateSwiper(); // Atualiza o Swiper após redimensionar
    },
    updateSwiper() {
      if (this.$refs.mySwiper && this.$refs.mySwiper.swiper) {
        this.$refs.mySwiper.swiper.update(); // Força a atualização do Swiper
      }
    },
    onSlideChange() {
      const swiper = this.$refs.mySwiper?.swiper; // Verifica se o swiper está definido
      if (swiper) {
        this.allowSlideNext = !swiper.isEnd;  // Desabilita o próximo slide se estiver no fim
        this.allowSlidePrev = !swiper.isBeginning;  // Desabilita o slide anterior se estiver no início
      }
    },
    onReachEnd() {
      const swiper = this.$refs.mySwiper?.swiper; // Verifica se o swiper está definido
      if (swiper) {
        this.allowSlideNext = false;  // Quando alcançar o final, desabilite o próximo
      }
    },
    onReachBeginning() {
      const swiper = this.$refs.mySwiper?.swiper; // Verifica se o swiper está definido
      if (swiper) {
        this.allowSlidePrev = false;  // Quando alcançar o início, desabilite o anterior
      }
    },
  },
  mounted() {
    this.navBarAnimation();
    this.navBarAnimation991px();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize", this.handleResizeBannerInformation);
    this.handleResizeBannerInformation();
  },
  onMounted() {
    this.$nextTick(() => {
      if (this.$refs.mySwiper && this.$refs.mySwiper.swiper) {
        this.swiperInstance = this.$refs.mySwiper.swiper;
        this.updateSwiper(); // Força a atualização quando o Swiper estiver montado
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.handleResizeBannerInformation);
  },
  beforeRouteLeave(to, from, next) {
    this.clearFiltro();
    next();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.product-list-container {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 190px;
  margin-left: -80px;
  z-index: 9999;
}

.product-list-container.sticky {
  margin-top: 170px;
  transition: 0.7s;
}

.product-list {
  position: fixed;
  width: 48%;
}

.container {
  margin-top: 70px;
  margin-bottom: 70px;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #0a67a8;
  margin-bottom: 30px;
}

h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #0a67a8;
}

.carousel {
    width: 100vw !important;
    height: 100%;
}

.carousel-item img {
    width: 100% !important;
}

#carouselExampleIndicators {
  margin-top: 146px;
  cursor: pointer;
}

.empty-text {
  height: 49px;
}

.card-space {
  display: flex;
  gap: 300px;
}

.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.swiper-slide {
  width: 630px;
  height: 630px;
}

.card {
  position: absolute;
  cursor: pointer;
  border: 1px solid rgba(44, 62, 80, 0.1);
  width: 300px;
  transition: box-shadow 0.3s;
  border-radius: 15px;
  overflow: hidden;
  height: 78% !important;
  transition: 0.5s;
}

.card:hover {
  height: 79% !important;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.card-buttons-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: transparent;
  display: none;
}
.card:hover .card-buttons-container {
  display: block;
}
.card-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;
  margin: 0 -10px 1px 0;
}
.card-buttons button {
  padding: 10px 16px;
  margin: -15px;
  margin-left: 40px;
  border: none;
  border-radius: 4px;
  width: 50%;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  transition: background-color 0.3s;
}

.card-destaque {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  border-radius: 15px;
  border: 1px solid #f7f7f8;
  background-color: green;
  color: #ffff;
  width: 30%;
  height: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 8px;
  margin: 10px 15px;
}

.card-highlight::before {
  content: "Mídia externa não inclusa";
  position: absolute;
  top: 0;
  right: 0;
  background-color: #192750;
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 0 5px 0 8px;
}

.text-center {
  border-bottom: 5px solid #0a67a8;
}

span {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #0a67a8;
}
.card img {
  margin-top: 20px;
  padding-bottom: 40px;
  width: 300px;
  height: 200px;
}

.informative-swiper {
  width: 100vw !important;
  margin-left: 16px;
  background-color: #e9ebed;
  overflow: hidden;
}

.informative-swiper-slide {
  display: flex;
  align-items: center;
  height: 200px;
  margin-left: 20px;
}

.router-slide {
  display: flex;
  text-decoration: none;
}

.informative-swiper-slide i {
  margin-right: 10px;
  font-size: 50px;
  color: #2c3e50;
}

.informative-swiper-slide .bi-laptop {
  margin: 20px 20px 0 -50px;
  font-size: 30px;
}

.router-house-laptop {
  cursor: default;
}

.informative-banner .informative-swiper-slide {
  width: 100%;
}

.informative-oferta span {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #2c3e50;
}

.informative-oferta p {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  color: #2c3e50;
}

.informative-oferta span:hover {
  color: #455c74;
}

.informative-oferta p:hover {
  color: #455c74;
}

@media (max-width: 991px) {
  .carousel {
    width: 100%;
    height: 100%;
  }

  .carousel-item img {
    width: 100%;
    height: 100%;
  }

  .container {
    width: 100%;
  }

  .swiper {
    width: 900px;
    margin-left: -150px !important;
    padding: 100px !important;
  }

  .swiper-slide-item {
    width: calc((100vw - 40px) / 2); /* 2 slides visíveis */
    margin-left: -100px !important;
    margin-right: 170px;
    object-fit: cover;
  }

  .card-space {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .card {
    width: 280px;
  }

  .card-destaque {
    width: 30%;
  }

  .empty-text {
    height: 50px;
  }

  .text-center img {
    max-width: 100%;
  }

  .product-list {
    margin: 55px 0 0 40px;
  }

  .product-list-container {
    margin-top: 110px;
  }

  .product-list-container.sticky {
    margin-top: 50px;
  }

  .informative-swiper {
    display: flex;
    margin-left: 16px !important;
  }

  .informative-swiper-slide {
    display: flex;
    align-items: center;
    height: 100px;
    margin: 0 110px 0 -60px;
  }

  .informative-swiper-slide .router-slide {
    display: flex;
    flex-direction: column;
  }

  .informative-swiper-slide .bi-laptop {
    margin: -54px 0 0 -11px;
  }
}

@media (max-width: 768px) {
  .product-list {
    width: 100%;
    margin: 72px 0 0 100px;
  }

  .carousel-item img {
    width: 100%;
    height: 100%;
  }

  .swiper {
    width: 700px;
    padding: 50px !important;
    margin-left: -100px;
  }

  .swiper-slide-item {
    width: 230px !important;    
    margin-left: -10px !important;
    margin-right: 15px;
    object-fit: cover;
  }

  .card-space {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 100px;
  }

  .empty-text {
    height: 49px;
    padding-bottom: 50px;
  }

  .card {
    width: 280px;    
    position: relative;
    max-width: 100%;
    height: 80% !important;
    margin-left: -40px !important;
  }

  .card:hover {
    height: 87% !important;
  }

  .card-destaque {
    max-width: 30%;
  }

  .card-highlight::before {
    max-width: 100%;
    padding: 8px 20px;
    font-size: 10px;
  }

  .informative-swiper {
    display: flex;
    width: 100%;
  }

  .informative-swiper-slide {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: 100px;
    margin: 0 -300px 0 10px;
  }

  .informative-swiper-slide .router-slide {
    display: flex;
    flex-direction: row;
    max-width: 100% !important;
  }

  .informative-swiper-slide .bi-laptop {
    margin: 20px 20px 0 -50px;
  }
}

@media (max-width: 568px) {
  .container {
    margin-top: 50px;
  }

  .empty-text {
    height: 48px;
    padding-bottom: 49px;
  }
  
  .card {
    width: 250px;
    height: 80% !important;
  }

  .card:hover {
    height: 87% !important;
  }

  .swiper {
    width: 460px;
    margin-left: -50px !important;
  }
  .swiper-slide {
    margin-right: 15px !important;
  }

  .informative-swiper {
    display: flex;
    max-width: 100vh !important;
    width: 100% !important;
    margin-left: 10px !important;
  }

  .informative-swiper-slide {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: 120px;
    margin: 0 -50px 0 0 !important;
    overflow: hidden;
  }
}

@media (max-width: 390px) {
  .swiper {
    width: 390px !important;
    
  }
  .swiper-slide {
    max-width: 250px;
    margin-left: -5px !important;
  }

  .card-buttons button {
    max-width: 50%;
  }

  .informative-swiper {
    display: flex;
    max-width: 100vh !important;
    width: 100% !important;
  }

  .informative-swiper-slide {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: auto;
    margin: 10px 40px 0 10px !important;
    overflow: hidden;
  }
}
</style>
