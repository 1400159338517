<template>
  <div
    class="modal fade"
    id="Modals"
    tabindex="-1"
    role="dialog"
    aria-labelledby="filterModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="filterModalLabel">Filtros</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="button-text">&times;</span>
          </button>
        </div>
        <div class="modal-body bg-light">
          <div>
            <span id="category"><strong>Categorias</strong></span>
            <span data-dismiss="modal" @click="handleClick('Pessoa Física')">Pessoa Física</span>
            <span data-dismiss="modal" @click="handleClick('Pessoa Jurídica')">Pessoa Jurídica</span>
          </div>
          <div class="card-body">
            <span id="validity"><strong>Validade</strong></span>
            <div class="form-check">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                data-dismiss="modal"
                @click="handleClick('1 Ano')"
              >
                1 Ano
              </label>
            </div>
            <div class="form-check">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                data-dismiss="modal"
                @click="handleClick('2 Anos')"
              >
                2 Anos
              </label>
            </div>
            <div class="form-check">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                data-dismiss="modal"
                @click="handleClick('3 Anos')"
              >
                3 Anos
              </label>
            </div>
            <div class="form-check">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                data-dismiss="modal"
                @click="handleClick('Todos')"
              >
                Todos
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalFilterCategory",
  methods: {
    handleClick(text) {
      // Emite o evento "text-selected" com o valor do texto clicado
      this.$emit('text-selected', text);
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    background-color: #0a67a8;
}

#priceModalLabel {
  color: #ffff;
}

.modal-header button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  border-radius: 50%;
  color: #ffff;
  border: 2px solid #ffffff;
  background-color: #0a67a8;
  margin-top: 2px;
  width: 40px;
  height: 40px;
}

.modal-header h5 {
  color: #ffff;
}

.modal-body span {
  display: flex;
  justify-content: flex-start;
  padding: 5px;
  color: #0a67a8;
  cursor: pointer;
}

.modal-body span:hover {
  color: rgba(0, 0, 0, 0.2);
}

.modal-body #category {
    font-size: 16px;
    color: #282d31;
    cursor: context-menu;
}

.modal-body #validity {
    font-size: 16px;
    color: #282d31;
    cursor: context-menu;
}

.form-check {
    display: flex;
    margin-left: -9px;
}

.form-check label:hover {
    color: #0a67a8;
    cursor: pointer;
}

strong {
  font-weight: bold;
}

.modal-footer {
  background-color: #0a67a8;
}

@media (max-width: 390px) {
  .modal-header button {
    margin-right: -15px !important;
    width: 40px;
    height: 40px;
    border: none;
  }  
}

</style>