<template>
  <div class="app-container">
    <NavBar :logo="logo_src" :alt="app_name" />
    <div class="product-list-container">
      <div v-if="isInputFilter = false" class="product-list bg-light" style="border-radius: 10px">
        <ProductItemVue
          v-for="(produto, index) in produtosFiltrados"
          :key="index"
          :produto="produto"
        />
      </div>
    </div>
  </div>
  <div class="container py-5">
    <div class="row d-flex justify-content-center my-4 mt-5">
      <div class="header-itens">
        <h5> Nossos Produtos</h5>
        <ModalFilterCategory  @text-selected="handleTextSelected"/>
        <div class="input-filter" data-toggle="modal" data-target="#Modals">
          <span>Filtrar</span>
          <i class="bi bi-funnel" id="filter-icon"></i>
        </div>
      </div>
      <section class="col-md-8 d-flex card-category">
        <div class="card mb-4 border-0">
          <div class="card-body d-flex flex-column mb-auto py-3 border-0">
            
            <span class="mb-4">Categorias</span>
            <p @click="handleClick('MEI')">Certificado Digital A1<br />para MEI</p>
            <p @click="handleClick('CPF')">
              Certificado Digital para<br />Pessoa Física (CPF)
            </p>
            <p @click="handleClick('CNPJ')">
              Certificado Digital para<br />Pessoa Jurídica (CNPJ)
            </p>
            <p @click="handleClick('Sem mídia')">
              Certificado digital sem<br />mídia externa (A1)
            </p>
            <p @click="handleClick('Sem mídia')">
              Certificado digital mídia<br />externa (A3)
            </p>
            <p @click="clearFiltro">Todos</p>
          </div>
          <div class="card-body d-flex flex-column mb-auto py-3 border-0">
            <span class="mb-4">Filtrar por</span>
            <span class="mb-2">Validade</span>
            <div class="form-check">
              <label class="form-check-label" for="flexCheckDefault" @click="handleClick('A1')">
                1 Ano
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label" for="flexCheckDefault" @click="handleClick('A3')">
                2 Anos
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label" for="flexCheckDefault" @click="handleClick('A3')">
                3 Anos
              </label>
            </div>
          </div>
          <div class="card-body"></div>
        </div>
      </section>

      <section class="container-card">
        <!-- Image -->
        <div class="row card-space d-flex justify-content-end">
          <div
            class="rounded mb-3 col-lg-3"
          >
            <swiper
              ref="mySwiper"
              :slides-per-view="slidesPerViewValue"

              :loop="false"
              :centeredSlides="false"
              :freeMode="false"
              :resistanceRatio="0"
              :initial-slide="0"
              :watchOverflow="true"
              :allow-slide-next="allowSlideNext"
              :allow-slide-prev="allowSlidePrev"
              class="swiper-container-full"            
            >
              <swiper-slide
                v-for="produto in produtosCard"
                :key="produto.id"
                :produto="produto"
                class="swiper-slide-item"
              >
                <div
                  class="card h-100"
                  @click="viewProduct(produto)"
                  v-if="produto.name.includes(tipoCertificado)"
                >
                  <div
                    :class="
                      produto.cardText === '' ? 'empty-text' : 'card-destaque'
                    "
                  >
                    {{ produto.cardText }}
                  </div>
                  <div
                    :class="
                      produto.cardHighlight === '' ? 'none' : 'card-highlight'
                    "
                  ></div>
                  <div class="text-center">
                    <img
                      class="card-img-top mx-auto"
                      :src="produto.image"
                      alt="Card image cap"
                    />
                  </div>
                  <div class="card-body boder">
                    <p class="card-text">{{ produto.name }}</p>
                    <h5 class="card-title">R$ {{ produto.price }}</h5>
                    <span class="card-title">{{ produto.pix }}</span>
                    <p class="card-text">
                      <small class="text-muted"
                        >ou R$ {{ produto.priceInstallments }} a prazo</small
                      >
                    </p>
                    <p>
                      <small class="text-muted">{{ produto.installments }}</small>
                    </p>
                  </div>
                  <div class="card-buttons-container">
                    <div class="card-buttons">
                      <button
                        type="button"
                        data-mdb-button-init
                        data-mdb-ripple-init
                        class="btn btn-primary btn-lg btn-block me-5"
                      >
                        <i class="bi bi-eye"></i>
                        Espiar
                      </button>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>

          </div>
        </div>
        <!-- Image -->
      </section>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/MainNavbar.vue";
import ProductItemVue from "../components/ProductItem.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import ModalFilterCategory from "@/components/ModalFilterCategory.vue";

export default {
  name: "ProdutosView",
  components: {
    NavBar,
    ProductItemVue,
    Swiper,
    SwiperSlide,
    ModalFilterCategory
  },
  computed: {
    ...mapGetters(['produtosFiltrados', 'isInputFilter']),
    ...mapGetters(["produtosCard"]),
  },
  data() {
    return {
      logo_src: "/img/conectec_logo.svg",
      app_name: "Conectec",
      tipoCertificado: "",
      selectedValues: [],
      selectedText: '',
      slidesPerViewValue: 5,
      spaceBetweenValue: -700,
    };
  },
  methods: {
    ...mapActions(["clearFiltro"]),
    navBarAnimation() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    navBarAnimation991px() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    viewProduct(produto) {
      this.$router.push({
        name: "ProdutoDetail",
        params: { id: produto.id },
        query: {
          name: produto.name,
          price: produto.price,
          pix: produto.pix,
          image: produto.image,
          priceInstallments: produto.priceInstallments,
          installments: produto.installments,
          cardHighlight: produto.cardHighlight,
        },
      });
    },
    handleClick(filtro) {
      // Faz o commit para alterar o filtro no Vuex
      this.$store.commit('setFiltro', { filtro, isInputFilter: false });
    },
    handleTextSelected(text) {
      // Recebe o texto emitido pelo modal e o armazena
      this.selectedText = text;
      var filtro;
      if(this.selectedText === "Pessoa Física") {
        filtro = "CPF";
        this.$store.commit('setFiltro', { filtro, isInputFilter: false });
      } else if(this.selectedText === "Pessoa Jurídica") {
        filtro = "CNPJ";
        this.$store.commit('setFiltro', { filtro, isInputFilter: false });
      } else if(this.selectedText === "1 Ano") {
        filtro = "A1";
        this.$store.commit('setFiltro', { filtro, isInputFilter: false });
      } else if(this.selectedText === "2 Anos" || this.selectedText === "3 Anos") {
        filtro = "A3";
        this.$store.commit('setFiltro', { filtro, isInputFilter: false });
      } else {
        filtro = "";
        this.$store.commit('setFiltro', { filtro, isInputFilter: false });
      }
    },
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log("slide change");
      };
      return {
        onSwiper,
        onSlideChange,
      };
    },
    handleResize() {
      // Ajusta o valor de spaceBetween baseado na resolução da tela
      if (window.innerWidth <= 390) {
        this.slidesPerViewValue = 1.5;
      } else if (window.innerWidth <= 768) {
        this.spaceBetweenValue = 10;
        this.slidesPerViewValue = 2;
      } else if (window.innerWidth <= 991) {
        this.spaceBetweenValue = 20;
        this.slidesPerViewValue = 3;
      } else {
        this.spaceBetweenValue = 30;
        this.slidesPerViewValue = 5;
      }
      this.updateSwiper(); // Atualiza o Swiper após redimensionar
    },
    updateSwiper() {
      if (this.$refs.mySwiper && this.$refs.mySwiper.swiper) {
        this.$refs.mySwiper.swiper.update(); // Força a atualização do Swiper
      }
    },
  },
  mounted() {
    this.navBarAnimation();
    this.navBarAnimation991px();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  onMounted() {
    this.$nextTick(() => {
      if (this.$refs.mySwiper && this.$refs.mySwiper.swiper) {
        this.swiperInstance = this.$refs.mySwiper.swiper;
        this.updateSwiper(); // Força a atualização quando o Swiper estiver montado
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeRouteLeave(to, from, next) {
    this.clearFiltro();
    next();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

#modal-filter {
  display: block;
}

.container-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.form-check label {
  margin-left: -10px;
  cursor: pointer;
}

.form-check label:hover {
  color: #0a67a8;
}

.card-body p:hover {
  color: #0a67a8;
}

.product-list-container {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 170px;
  margin-left: -80px;
  z-index: 9999;
}

.product-list-container.sticky {
  margin-top: 170px;
  transition: 0.7s;
}

.product-list {
  position: fixed;
  width: 48%;
}

.h-100 {
  cursor: pointer;
  border: 1px solid rgba(44, 62, 80, 0.1);
  width: 200px;
  transition: box-shadow .3s;
  border-radius: 40px;
  overflow: hidden;
  border-radius: 15px;
  height: 200px;
  margin: 10px;
  height: 95% !important;
  transition: 0.5s;
}

.h-100:hover {
  height: 100% !important;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1); 
}

.swiper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -680px !important;
  width: 1100px;
  height: 100vh;
  overflow: hidden;
}

.swiper-slide {
  width: 210px !important;
  height: 530px;
}

.header-itens {
  display: none;
}

.card-buttons-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: transparent;
  display: none;
}
.card:hover .card-buttons-container {
  display: block;
}
.card-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;
  margin: 0 -15px -15px 0;
}
.card-buttons button {
  padding: 10px 16px;
  margin: 8px;
  margin-left: 25px;
  border: none;
  border-radius: 4px;
  width: 200px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  transition: 0.3s;
}

.card-category {
  margin: 120px 0 0 -600px;
  font-size: 14px;
  text-align: justify;
}

.card-body .mb-2 {
  font-size: 14px;
}

.card-category span {
  font-size: 20px;
  text-align: justify;
  font-weight: 700;
}

.card-space {
  margin: -650px 0 0 210px;
}

.empty-text {
  height: 40px;
}

.card-destaque {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  border-radius: 15px;
  border: 1px solid #f7f7f8;
  background-color: green;
  color: #ffff;
  width: 40%;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 8px;
  margin: 10px 15px;
}

.card-highlight::before {
  content: "Mídia externa não inclusa";
  position: absolute;
  top: 0;
  right: 0;
  background-color: #192750;
  color: white;
  font-size: 10px;
  padding: 6px 16px;
  border-radius: 0 5px 0 8px;
}

.text-center {
  border-bottom: 5px solid #0a67a8;
}

h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #0a67a8;
}

span {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #0a67a8;
}
.card img {
  width: 200px;
  height: 200px;
}

.mb-4 p {
  cursor: pointer;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }

  .product-list-container {
    margin-top: 220px;
  }

  .product-list-container.sticky {
    margin-top: 155px;
  }

  .product-list {
    margin: 10px 0 0 120px;
  }

  .card-destaque {
    max-width: 40%;
  }

  .card-space {
    display: flex;
    justify-content: center;
    margin-left: 500px;
  }

  .header-itens {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 90px;
  }

  .header-itens .input-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(44, 62, 80, 0.1);
    border-radius: 10px;
    width: 40%;
    height: 40px;
    padding: 10px;
    transition: 0.5s;
    cursor: pointer;
  }

  .header-itens .input-filter span, #filter-icon {
    color: #000;
    transition: 0.5s;
  }

  .header-itens .input-filter:hover {
    border: 1px solid rgba(9, 4, 56, 0.322);
  }

  .header-itens .input-filter span:hover, i:hover {
    color: #0a67a8;
  }

  .card-category {
    display: flex;
    justify-content: flex-start;
    margin-left: -250px !important;
  }

  .card {
    max-width: 100%;
  }

  .swiper {
    width: 700px;
    padding: 90px !important;
    margin: -220px 0 0 -250px !important;
    height: 100vh !important;
  }

  .swiper-slide-item {
    width: calc((100vw - 40px) / 2);
    margin-left: -100px !important;
    margin-right: 100px !important;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }

  .product-list {
    margin-top: 10px;
  }

  .card-category {
    display: none !important;
  }

  .card-space {
    display: flex;
    justify-content: center;
    margin: 200px;
  }

  .card {
    max-width: 100%;
  }

  .header-itens {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 100px 0 -10px 0;
  }

  .header-itens .input-filter {
    max-width: 100%;
  }

  .swiper {
    max-width: 100vh;
    padding: 100px !important;
    margin: -200px 0 0 -100px !important;
    height: 650px !important;
  }

  .swiper-slide-item {
    width: calc((100vw - 40px) / 2); /* 2 slides visíveis */
    margin-left: -90px !important;
  }

  .product-list {
    width: 100%;
    margin-left: 100px;
  }
}

@media (max-width: 568px) {
  .product-list {
    margin-top: 20px;
  }
  .card-space {
    display: flex;
    justify-content: center;
    margin: 55px;
  }

  .card {
    max-width: 100%;
    max-height: 100%;
  }

  .header-itens {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 100px 0 30px 0;
  }

  .header-itens .input-filter {
    max-width: 100%;
  }

  .swiper {
    max-width: 100vh;
    padding: 100px !important;
    margin: -100px 0 0 -10px !important;
    height: 100vh;
  }

  .swiper-slide-item {
    width: calc((100vw - 40px) / 2); /* 2 slides visíveis */
    margin-left: -25px !important;
    margin-right: 35px !important;
  }

  .product-list {
    width: 100%;
    margin-left: 100px;
  }
}

  @media (max-width: 390px) {
    .header-itens .input-filter {
      width: 50%;
    }

    .swiper {
      max-width: 100vh;
      padding: 100px !important;
      margin: -100px 0 0 100px !important;
      height: 100vh;
    }

    .swiper-slide-item {
      width: calc((100vw - 40px) / 2); /* 2 slides visíveis */
      margin-left: 5px !important;
      margin-right: -1px !important;
    }

    .product-list {
      width: 100%;
      margin-left: 100px;
      margin-top: 20px;
    }
  }
</style>